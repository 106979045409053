@import "normalize.css/normalize.css";
@import "functions";
@import "variables";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
html {
  font-family: "Montserrat", sans-serif;
  color: $primary-color;
}

::-moz-selection {
  color: white;
  background: rgba($secondary-color, 0.4);
}

::selection {
  color: white;
  background: rgba($secondary-color, 0.4);
}
